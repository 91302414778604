import * as React from 'react';
import {
    Box,
    Container,
    Tabs,
    TabList,
    TabPanels,
    TabPanel,
    Tab,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Heading
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import BlueBumps from '../../images/BlueBumps.svg';
import { PrismicRichText } from '@prismicio/react';

const BumpBox = styled(Box)`
    background-image: url(${BlueBumps});
    background-repeat: repeat-x;
`

const TabTitle = styled(Tab)`
    font-family: Optima, sans-serif;
    font-size: 1.5rem;
    color: #99b4ff;
    border-bottom: 2px solid #99b4ff;
`
const AccordionTitle = styled(Heading)`
    font-family: Optima, sans-serif;
    color: white;
    > button > div {
        font-size: 1.5rem;
    }
`

export default function HomepageTabs(props) {
    return (
        <Box position="relative" zIndex="1" marginTop="-1.5rem" mb="12">
            <BumpBox>
            &nbsp;
            </BumpBox>
            <Box bg="brand.500" p={{base: "4", lg: "12"}}>
                <Container maxW="container.lg">
                    <Box color="white" display={{ base: "none", lg: "block"}}>
                    <Tabs isFitted>
                        <TabList>
                            <TabTitle _selected={{ color: 'white', borderBottom: '2px solid white' }} _active={{ background: 'none'}}>{props.tab1Title}</TabTitle>
                            <TabTitle _selected={{ color: 'white', borderBottom: '2px solid white' }} _active={{ background: 'none'}}>{props.tab2Title}</TabTitle>
                            <TabTitle _selected={{ color: 'white', borderBottom: '2px solid white' }} _active={{ background: 'none'}}>{props.tab3Title}</TabTitle>
                        </TabList>

                        <TabPanels>
                            <TabPanel>
                                <PrismicRichText field={props.tab1Content} />
                            </TabPanel>
                            <TabPanel>
                                <PrismicRichText field={props.tab2Content} />
                            </TabPanel>
                            <TabPanel>
                                <PrismicRichText field={props.tab3Content} />
                            </TabPanel>
                        </TabPanels>
                        </Tabs>
                    </Box>
                </Container>
                <Box display={{ base: "block", lg: "none"}}>
                    <Accordion allowToggle color="white" >
                        <AccordionItem border="none">
                            <AccordionTitle>
                            <AccordionButton>
                                <Box flex='1' textAlign='left'>
                                    {props.tab1Title}
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            </AccordionTitle>
                            <AccordionPanel pb={4}>
                                <PrismicRichText field={props.tab1Content} />
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem border="none">
                            <AccordionTitle>
                            <AccordionButton>
                                <Box flex='1' textAlign='left'>
                                    {props.tab2Title}
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            </AccordionTitle>
                            <AccordionPanel pb={4}>
                                <PrismicRichText field={props.tab2Content} />
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem border="none">
                            <AccordionTitle>
                            <AccordionButton>
                                <Box flex='1' textAlign='left'>
                                    {props.tab3Title}
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            </AccordionTitle>
                            <AccordionPanel pb={4}>
                                <PrismicRichText field={props.tab3Content} />
                            </AccordionPanel>
                        </AccordionItem>

                        </Accordion>
                        </Box>
            </Box>
        </Box>
    )
}