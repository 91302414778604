import React from "react";
import { Link } from "gatsby";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

import { Box, Heading, Flex, Container, Button } from "@chakra-ui/react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { ChevronRightIcon } from "@chakra-ui/icons";

export default function HomepageSlider(props) {
  return (
    <>
        <Box display={{ base: 'none', md: 'block'}} h="85vh">
          <Splide 
            options={{
                type: "loop",
                autoplay: false,
                interval: 9000,
                pauseOnHover: false,
                arrows: true,
                rewind: true,
                pagination: false,
                height: "85vh",
          }}>
            {props.slides.map((slide, index) => {
              return (
                <SplideSlide key={index}>
                  <Box h="100%">
                      <Box w="100%" h="100%" className="slide-wrapper">
                          <GatsbyImage
                            image={getImage(slide.slider_image.localFile)}
                            alt={slide.slider_image.alt}
                            imgStyle={{ objectFit: "cover" }}
                            aspectRatio={16 / 9}
                          />
                      </Box>
                      <Flex
                        w="100%"
                        h="100%"
                        flexDirection="column"
                        position="absolute"
                        top="0"
                        left="0"
                        justifyContent="flex-start"
                        alignItems="center"
                        bg="rgba(0,0,0,0.25);"
                        transform="translateZ(1px)" // fixes a bug in Safari where the text has a delay in appearing
                      >
                        <Container maxW="container.lg" mt={{base: "4", lg: "24"}}>
                            <Heading color="white" as="h1" textAlign="center" fontSize="clamp(2rem, 5vw, 4rem)" >
                              {slide?.headline.text}
                            </Heading>
                            <Heading color="white" as="h2" variant="h2" fontSize="1.75rem" textAlign="center" mb="12">
                                {slide?.subtitle.text}
                            </Heading>
                            {slide?.slider_link.url && (
                              <Box textAlign="center">
                              <Link to={slide?.slider_link.url} textAlign="center">
                                <Button size="lg" variant="snowscapes">
                                  {slide?.slider_link_label} <ChevronRightIcon fontSize="xl" />
                                </Button>
                              </Link>
                           </Box>
                            )}
                        </Container>
                      </Flex>
                  </Box>
                </SplideSlide>
              );
            })}
          </Splide>
        </Box>
        <Box display={{ base: 'block', md: 'none'}}>
        <Splide
          options={{
            type: "loop",
            autoplay: true,
            interval: 9000,
            pauseOnHover: false,
            arrows: true,
            rewind: true,
            pagination: false
          }}
        >
          {props.slides.map((slide, index) => {
            return (
              <SplideSlide key={index}>
                <GatsbyImage
                  image={getImage(slide.mobile_slider_image.localFile)}
                  alt={slide.mobile_slider_image.alt}
                />
                <Flex
                  w="100%"
                  h="100%"
                  flexDirection="column"
                  position="absolute"
                  top="0"
                  left="0"
                  justifyContent="flex-start"
                  alignItems="center"
                  bg="rgba(0,0,0,0.25);"
                  transform="translateZ(1px)" // fixes a bug in Safari where the text has a delay in appearing
                >
                  <Container maxW="container.lg" mt={{base: "8", lg: "12"}}>
                      <Heading color="white" as="h1" textAlign="center" fontSize="clamp(2rem, 5vw, 4rem)" >
                        {slide?.headline.text}
                      </Heading>
                      <Heading color="white" as="h2" variant="h2" fontSize="1.25rem" textAlign="center" mb="12">
                          {slide?.subtitle.text}
                      </Heading>
                      <Box textAlign="center">
                              <Link to={slide.slider_link.url} textAlign="center">
                                <Button size="lg" variant="snowscapes">
                                  {slide.slider_link_label} <ChevronRightIcon fontSize="xl" />
                                </Button>
                              </Link>
                           </Box>
                  </Container>
                </Flex>
              </SplideSlide>
            );
          })}
        </Splide>
      </Box>
    </>
  );
}
